import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n/config';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { FirebaseAuthProvider } from './services/FirebaseAuthContext';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <FirebaseAuthProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </FirebaseAuthProvider>
);
