import {
  Auth,
  Unsubscribe,
  getAuth,
  User,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from 'firebase/auth';
import {
  getFirestore,
  Firestore,
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  where,
  query,
  orderBy,
  onSnapshot,
  QuerySnapshot,
  Timestamp,
} from 'firebase/firestore';
import { getFunctions, Functions, httpsCallable } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import {
  AutoCompleteMode,
  IBackground,
  IError,
  IResult,
  ISpriteAnimation,
  MessageResourceLanguage,
  IQuestion,
  IQuestionType,
} from '../../jasmine-common-lib/Types';
import {
  errorResultOf,
  FailableResult,
  FailableResultWithValue,
  isError,
  successResult,
  successResultOf,
} from '../../jasmine-common-lib/utils/FailableResult';
import {
  ALL_JASMINE_COLOR_THEME_NAMES,
  DEFAULT_SYNTAX_HIGHLIGHT_THEME,
  IJasmineColorThemeNames,
  IOldJasmineColorThemeNames,
  OLD_ALL_JASMINE_COLOR_THEME_NAMES,
  OLD_NEW_JASMINE_COLOR_THEME_NAME_MAP,
} from '../../jasmine-common-lib/ui/editor/JasmineColorThemes';

export interface FirebaseConfiguration {
  apiKey: string | undefined;
  authDomain: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  appId: string | undefined;
  measurementId: string | undefined;
}

const FUNCTIONS_REGION_ASIA = 'asia-northeast1';

export interface IUserData {
  uid?: string;
  projectId: string | undefined;
  onboarding?: boolean;
  notificationLastReadTime?: Date;
  selectedEducationInstitutionId?: string;
  syntaxHighlightTheme: IJasmineColorThemeNames;
  testFeature: boolean;
  autoCompleteMode: AutoCompleteMode;
  updatedAt?: Date;
}

export interface IUserInformation {
  uid: string;
  displayName: string;
  photoURL?: string;
  email: string;
  birthday?: string;
  emailVerifiedAt?: Date;
  completed?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IFetchUserInformationExistsResult {
  type: 'exists';
  userInformation: IUserInformation;
}

export interface IFetchUserInformationNotExistsResult {
  type: 'notExists';
}

export type IFetchUserInformationResult = FailableResultWithValue<
  IFetchUserInformationExistsResult | IFetchUserInformationNotExistsResult,
  IError
>;

export type IProjectVisibility = 'public' | 'hidden' | 'education_institution';

export type ISpriteDirectionToAnimation = Record<number, number>;

export interface IProject {
  id?: string;
  uid?: string;
  author?: string;
  name: string;
  code: string;
  testCode: string;
  preparedAnimations: Record<number, ISpriteAnimation>;
  preparedBackgrounds: Record<number, IBackground>;
  preparedSpriteDirectionToAnimations: Record<
    number,
    ISpriteDirectionToAnimation
  >;
  visibility: IProjectVisibility;
  sourceProjectId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  // Following properties are not stored in the database.
  // Instead, they are set by the result of the `CheckProjectCanBeOpened` Cloud Functions.
  isOwnerBelongsToEducationInstitution?: boolean;
  ownerEducationInstitutionId?: string | undefined;
}

export type INotificationMessageReceiverType =
  | 'all'
  | 'education_institution'
  | 'education_institution_classroom';

export interface INotificationMessage {
  id: string;
  title: string;
  message: string;
  url: string;
  createdAt: Date;
  receiverType: INotificationMessageReceiverType;
  // This value is empty string when the receiverType is 'all'.
  educationInstitutionId: string;
  // This value is empty string when the receiverType is 'all' or 'education_institution'.
  educationInstitutionClassroomId: string;
}

export interface IEducationInstitution {
  id: string;
  name: string;
  supportedLocale: MessageResourceLanguage;
  gameTutorialVisible: boolean;
  docsMenu: string;
  questionsFeatureAvailable: boolean;
  defaultProjectVisibility: IProjectVisibility;
  classrooms: IEducationInstitutionClassroom[];
  createdAt: Date;
  updatedAt: Date;
}

export type IEducationInstitutionUserType = 'student' | 'teacher';

export interface IEducationInstitutionSimple {
  id: string;
  name: string;
  userType: IEducationInstitutionUserType;
}

export interface IEducationInstitutionUser {
  id: string;
  email: string;
  userType: IEducationInstitutionUserType;
  educationInstitution: IEducationInstitution;
  educationInstitutions: IEducationInstitutionSimple[];
  createdAt: Date;
}

export interface IEducationInstitutionClassroom {
  id: string;
  name: string;
  schoolYear: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICheckProjectCanBeOpenedResult {
  canBeOpened: boolean;
  isOwnerBelongsToEducationInstitution: boolean;
  ownerEducationInstitutionId: string | undefined;
}

export class Firebase {
  private readonly auth: Auth;
  private readonly db: Firestore;
  private readonly functions: Functions;

  private unsubscribeAuthStateChanged: Unsubscribe | undefined;
  private unsubscribeNotificationMessagesChanged: Unsubscribe | undefined;
  private unsubscribeNotificationForEducationInstitutionMessagesChanged:
    | Unsubscribe
    | undefined;
  private unsubscribeQuestionsChanged: Unsubscribe | undefined;
  private unsubscribeQuestionsForTeachersChanged: Unsubscribe | undefined;

  constructor(configuration: FirebaseConfiguration) {
    const app = initializeApp(configuration);
    this.auth = getAuth(app);
    this.db = getFirestore(app);
    this.functions = getFunctions(app, FUNCTIONS_REGION_ASIA);
  }

  getAuth(): Auth {
    return this.auth;
  }

  getFirestore(): Firestore {
    return this.db;
  }

  async signInWithGoogleWithRedirect(selectAccount: boolean): Promise<void> {
    const provider = new GoogleAuthProvider();
    if (selectAccount) {
      provider.setCustomParameters({
        prompt: 'select_account',
      });
    }
    await signInWithRedirect(this.auth, provider);
  }

  async signInWithMicrosoftWithRedirect(selectAccount: boolean): Promise<void> {
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('mail.read');
    if (selectAccount) {
      provider.setCustomParameters({
        prompt: 'select_account',
      });
    }
    await signInWithRedirect(this.auth, provider);
  }

  async signInWithAppleWithRedirect(): Promise<void> {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    provider.setCustomParameters({
      locale: 'ja_JP',
    });
    await signInWithRedirect(this.auth, provider);
  }

  async signInWithEmailAndPassword(
    emailAddress: string,
    password: string
  ): Promise<IResult> {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        emailAddress,
        password
      );
      if (userCredential.user.emailVerified) {
        return {
          success: true,
        };
      } else {
        await this.signOut();
        return {
          success: false,
          error: 'Signing in with the email address and the password failed',
        };
      }
    } catch (error: unknown) {
      console.error(error);
      return {
        success: false,
        error: 'Signing in with the email address and password failed',
        cause: error,
      };
    }
  }

  subscribeAuthState(callback: (user: User | null) => void): void {
    this.unsubscribeAuthStateChanged && this.unsubscribeAuthStateChanged();
    this.unsubscribeAuthStateChanged = this.auth.onAuthStateChanged(
      (user: User | null) => {
        callback(user);
      }
    );
  }

  getCurrentAuthenticatedUser(): User | null {
    return this.auth.currentUser;
  }

  getCurrentAuthenticatedUserOrThrow(): User {
    const user = this.getCurrentAuthenticatedUser();
    if (user === null) {
      throw new Error('Not signed in yet.');
    }
    return user;
  }

  async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  async fetchUserInformation(
    targetUid: string | undefined = undefined
  ): Promise<IFetchUserInformationResult> {
    try {
      const user = this.getCurrentAuthenticatedUser();
      if (user === null) {
        return errorResultOf({
          message: 'Updating user information failed because not logged in yet',
        });
      }
      const users = collection(this.db, 'users');
      const v1 = doc(users, 'v1');
      const profiles = collection(v1, 'profiles');
      const profile = doc(profiles, targetUid ? targetUid : user.uid);
      const snapshot = await getDoc(profile);
      if (snapshot.exists()) {
        return successResultOf({
          type: 'exists',
          userInformation: {
            uid: snapshot.id,
            displayName: snapshot.data().displayName as string,
            email: snapshot.data().email as string,
            photoURL: snapshot.data().photoURL as string,
            birthday: snapshot.data().birthday as string,
            emailVerifiedAt: snapshot.data().emailVerifiedAt
              ? (snapshot.data().emailVerifiedAt as Timestamp).toDate()
              : undefined,
            completed: snapshot.data().completed as boolean,
            createdAt: snapshot.data().createdAt
              ? (snapshot.data().createdAt as Timestamp).toDate()
              : new Date(),
            updatedAt: (snapshot.data().updatedAt as Timestamp).toDate(),
          },
        });
      } else {
        return successResultOf({ type: 'notExists' });
      }
    } catch (error) {
      return errorResultOf({
        message: 'Fetching user information failed',
        cause: error,
      });
    }
  }

  async updateUserInformation(props: {
    displayName?: string;
    email?: string;
    photoURL?: string;
    birthday?: string;
    completed?: boolean;
  }): Promise<FailableResultWithValue<IUserInformation, IError>> {
    try {
      const users = collection(this.db, 'users');
      const v1 = doc(users, 'v1');
      const profiles = collection(v1, 'profiles');
      const user = this.getCurrentAuthenticatedUser();
      if (user === null) {
        return errorResultOf({
          message: 'Updating user information failed because not logged in yet',
        });
      }
      const profile = doc(profiles, user.uid);
      const snapshot = await getDoc(profile);
      let userInformation: IUserInformation;
      if (snapshot.exists()) {
        userInformation = {
          uid: snapshot.id,
          displayName:
            props.displayName ?? (snapshot.data().displayName as string),
          email: props.email ?? (snapshot.data().email as string),
          photoURL: props.photoURL ?? (snapshot.data().photoURL as string),
          createdAt: snapshot.data().createdAt
            ? (snapshot.data().createdAt as Timestamp).toDate()
            : new Date(),
          updatedAt: new Date(),
        };
        if (props.birthday) {
          userInformation.birthday = props.birthday;
        } else if (snapshot.data().birthday) {
          userInformation.birthday = snapshot.data().birthday as string;
        }
        if (snapshot.data().emailVerifiedAt) {
          userInformation.emailVerifiedAt = (
            snapshot.data().emailVerifiedAt as Timestamp
          ).toDate();
        } else if (user.emailVerified) {
          userInformation.emailVerifiedAt = new Date();
        }
        if (props.completed !== undefined) {
          userInformation.completed = props.completed;
        } else if (snapshot.data().completed !== undefined) {
          userInformation.completed = snapshot.data().completed as boolean;
        }
      } else {
        userInformation = {
          uid: user.uid,
          displayName: props.displayName ?? user.displayName ?? '',
          email: props.email ?? user.email ?? '',
          photoURL: props.photoURL ?? user.photoURL ?? undefined,
          createdAt: new Date(),
          updatedAt: new Date(),
        };
        if (props.birthday) {
          userInformation.birthday = props.birthday;
        }
        if (user.emailVerified) {
          userInformation.emailVerifiedAt = new Date();
        }
        if (props.completed !== undefined) {
          userInformation.completed = props.completed;
        }
      }
      const ref = doc(profiles, userInformation.uid);
      await setDoc(ref, userInformation, { merge: true });
      return successResultOf(userInformation);
    } catch (error) {
      return errorResultOf({
        message: 'Updating user information failed',
        cause: error,
      });
    }
  }

  private async updateUserData(
    userData: IUserData
  ): Promise<FailableResult<IError>> {
    try {
      const users = collection(this.db, 'users');
      const v1 = doc(users, 'v1');
      const data = collection(v1, 'data');
      const user = this.getCurrentAuthenticatedUser();
      if (user === null) {
        return errorResultOf({
          message: 'Updating user data failed because not logged in yet',
        });
      }
      const ref = doc(data, user.uid);
      const obj: Partial<IUserData> = {
        uid: user.uid,
        updatedAt: new Date(),
      };
      if (userData.projectId !== undefined) {
        obj.projectId = userData.projectId;
      }
      if (userData.onboarding !== undefined) {
        obj.onboarding = userData.onboarding;
      }
      await setDoc(ref, obj, { merge: true });
      return successResult();
    } catch (e) {
      console.error(e);
      const errorMessage = e instanceof Error ? e.message : 'Unknown reason';
      return errorResultOf({
        message: `Updating user data failed: ${errorMessage}`,
        cause: e,
      });
    }
  }

  async updateUserSelectedEducationInstitution(
    selectedEducationInstitutionId: string
  ): Promise<FailableResult<IError>> {
    try {
      const users = collection(this.db, 'users');
      const v1 = doc(users, 'v1');
      const data = collection(v1, 'data');
      const user = this.getCurrentAuthenticatedUser();
      if (user === null) {
        return errorResultOf({
          message:
            'Updating user notification last read time failed because not logged in yet',
        });
      }
      const ref = doc(data, user.uid);
      const snapshot = await getDoc(ref);
      if (snapshot.exists()) {
        await updateDoc(ref, { selectedEducationInstitutionId });
        return successResult();
      } else {
        return await this.updateUserData({
          projectId: undefined,
          selectedEducationInstitutionId: selectedEducationInstitutionId,
          syntaxHighlightTheme: DEFAULT_SYNTAX_HIGHLIGHT_THEME,
          testFeature: false,
          autoCompleteMode: AutoCompleteMode.Disabled,
        });
      }
    } catch (e) {
      console.error(e);
      const errorMessage = e instanceof Error ? e.message : 'Unknown reason';
      return errorResultOf({
        message: `Updating user selected education institution failed: ${errorMessage}`,
        cause: e,
      });
    }
  }

  private getSyntaxHighlightThemeName(name: string): IJasmineColorThemeNames {
    if (ALL_JASMINE_COLOR_THEME_NAMES.find((x) => x === name)) {
      return name as IJasmineColorThemeNames;
    }
    if (OLD_ALL_JASMINE_COLOR_THEME_NAMES.find((x) => x === name)) {
      return OLD_NEW_JASMINE_COLOR_THEME_NAME_MAP[
        name as IOldJasmineColorThemeNames
      ];
    }
    return DEFAULT_SYNTAX_HIGHLIGHT_THEME;
  }

  async fetchUserData(): Promise<IUserData> {
    const users = collection(this.db, 'users');
    const v1 = doc(users, 'v1');
    const data = collection(v1, 'data');
    const user = this.getCurrentAuthenticatedUser();
    if (user === null) {
      throw new Error('Fetching user data failed because not logged in yet');
    }
    const ref = doc(data, user.uid);
    const snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      return {
        uid: user.uid,
        projectId: snapshot.data().projectId as string,
        onboarding: snapshot.data().onboarding as boolean,
        notificationLastReadTime: snapshot.data().notificationLastReadTime
          ? (snapshot.data().notificationLastReadTime as Timestamp).toDate()
          : new Date(),
        syntaxHighlightTheme: this.getSyntaxHighlightThemeName(
          snapshot.data().syntaxHighlightTheme as string
        ),
        testFeature: snapshot.data().testFeature as boolean,
        autoCompleteMode: snapshot.data().autoCompleteMode
          ? (snapshot.data().autoCompleteMode as AutoCompleteMode)
          : AutoCompleteMode.Disabled,
        updatedAt: (snapshot.data().updatedAt as Timestamp).toDate(),
      };
    } else {
      return {
        uid: user.uid,
        projectId: undefined,
        updatedAt: undefined,
        notificationLastReadTime: new Date(),
        syntaxHighlightTheme: DEFAULT_SYNTAX_HIGHLIGHT_THEME,
        testFeature: false,
        autoCompleteMode: AutoCompleteMode.Disabled,
      };
    }
  }

  async subscribeNotificationMessages(
    educationInstitutionId: string | undefined,
    listener: (message: INotificationMessage) => void
  ): Promise<void> {
    // Register the snapshot listener only when the user is not anonymous.
    const user = this.getCurrentAuthenticatedUser();
    if (user !== null && !user.isAnonymous) {
      const userData = await this.fetchUserData();
      this.unsubscribeNotificationMessagesChanged &&
        this.unsubscribeNotificationMessagesChanged();
      this.unsubscribeNotificationForEducationInstitutionMessagesChanged &&
        this.unsubscribeNotificationForEducationInstitutionMessagesChanged();
      const notifications = collection(this.db, 'notifications');
      const v1 = doc(notifications, 'v1');
      const messages = collection(v1, 'messages');
      const queryForAll = query(
        messages,
        where('receiverType', '==', 'all'),
        where('createdAt', '>', userData.notificationLastReadTime),
        orderBy('createdAt', 'asc')
      );
      const handleQuerySnapshot = (snapshot: QuerySnapshot): void => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            listener({
              id: change.doc.id,
              title: change.doc.data().title as string,
              message: change.doc.data().message as string,
              url: change.doc.data().url as string,
              createdAt: (change.doc.data().createdAt as Timestamp).toDate(),
              receiverType: change.doc.data()
                .receiverType as INotificationMessageReceiverType,
              educationInstitutionId: change.doc.data()
                .educationInstitutionId as string,
              educationInstitutionClassroomId: change.doc.data()
                .educationInstitutionClassroomId as string,
            });
          }
        });
      };
      this.unsubscribeNotificationMessagesChanged = onSnapshot(
        queryForAll,
        (snapshot) => {
          handleQuerySnapshot(snapshot);
        }
      );
      if (educationInstitutionId !== undefined) {
        const queryForEducationInstitution = query(
          messages,
          where('receiverType', 'in', [
            'education_institution',
            'education_institution_classroom',
          ]),
          where('educationInstitutionId', '==', educationInstitutionId),
          where('createdAt', '>', userData.notificationLastReadTime),
          orderBy('createdAt', 'asc')
        );
        this.unsubscribeNotificationForEducationInstitutionMessagesChanged =
          onSnapshot(queryForEducationInstitution, (snapshot) => {
            handleQuerySnapshot(snapshot);
          });
      }
    }
  }

  unsubscribeNotificationMessages(): void {
    this.unsubscribeNotificationMessagesChanged &&
      this.unsubscribeNotificationMessagesChanged();
    this.unsubscribeNotificationForEducationInstitutionMessagesChanged &&
      this.unsubscribeNotificationForEducationInstitutionMessagesChanged();
  }

  async checkUserBelongsToEducationInstitution(): Promise<
    FailableResultWithValue<IEducationInstitutionUser | undefined, IError>
  > {
    const checkEducationInstitutionUser = httpsCallable<
      unknown,
      FailableResultWithValue<
        {
          found: boolean;
          educationInstitutionUser: IEducationInstitutionUser | undefined;
        },
        { code: string; message: string }
      >
    >(this.functions, 'checkEducationInstitutionUser');
    const checkEducationInstitutionUserResult =
      await checkEducationInstitutionUser();
    const checkResult = checkEducationInstitutionUserResult.data;
    if (isError(checkResult)) {
      console.error(checkResult);
      return errorResultOf({
        message: `${checkResult.error.code}: ${checkResult.error.message}`,
      });
    }
    return checkResult.value.found
      ? successResultOf(checkResult.value.educationInstitutionUser)
      : successResultOf(undefined);
  }

  subscribeQuestions(
    projectId: string,
    listener: (question: IQuestion) => void
  ): void {
    // Register the snapshot listener only when the user is not anonymous.
    const user = this.getCurrentAuthenticatedUser();
    if (user !== null && !user.isAnonymous) {
      this.unsubscribeQuestionsChanged && this.unsubscribeQuestionsChanged();
      const users = collection(this.db, 'users');
      const v1 = doc(users, 'v1');
      const questions = collection(v1, 'questions');
      const q = query(
        questions,
        where('projectId', '==', projectId),
        orderBy('createdAt', 'asc')
      );
      this.unsubscribeQuestionsChanged = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            listener({
              id: change.doc.id,
              projectId: change.doc.data().projectId as string,
              projectName: change.doc.data().projectName as string,
              uid: change.doc.data().uid as string,
              displayName: change.doc.data().displayName as string,
              photoUrl: change.doc.data().photoUrl as string,
              questionType: change.doc.data().questionType as IQuestionType,
              answered: change.doc.data().answered as boolean,
              content: change.doc.data().content as string,
              educationInstitutionId: change.doc.data()
                .educationInstitutionId as string,
              educationInstitutionClassrooms: change.doc.data()
                .educationInstitutionClassrooms
                ? (change.doc.data().educationInstitutionClassrooms as {
                    id: string;
                    name: string;
                  }[])
                : [],
              createdAt: (change.doc.data().createdAt as Timestamp).toDate(),
            });
          }
        });
      });
    }
  }

  unsubscribeQuestions(): void {
    this.unsubscribeQuestionsChanged && this.unsubscribeQuestionsChanged();
  }

  subscribeQuestionsForTeachers(
    educationInstitutionId: string,
    listener: (event: {
      question: IQuestion;
      changeType: 'added' | 'removed';
    }) => void
  ): void {
    // Register the snapshot listener only when the user is not anonymous.
    const user = this.getCurrentAuthenticatedUser();
    if (user !== null && !user.isAnonymous) {
      this.unsubscribeQuestionsForTeachersChanged &&
        this.unsubscribeQuestionsForTeachersChanged();
      const users = collection(this.db, 'users');
      const v1 = doc(users, 'v1');
      const questions = collection(v1, 'questions');
      const q = query(
        questions,
        where('educationInstitutionId', '==', educationInstitutionId),
        where('questionType', '==', 'question'),
        where('answered', '==', false),
        orderBy('createdAt', 'asc')
      );
      this.unsubscribeQuestionsForTeachersChanged = onSnapshot(
        q,
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'removed') {
              listener({
                question: {
                  id: change.doc.id,
                  projectId: change.doc.data().projectId as string,
                  projectName: change.doc.data().projectName as string,
                  uid: change.doc.data().uid as string,
                  displayName: change.doc.data().displayName as string,
                  photoUrl: change.doc.data().photoUrl as string,
                  questionType: change.doc.data().questionType as IQuestionType,
                  answered: change.doc.data().answered as boolean,
                  content: change.doc.data().content as string,
                  educationInstitutionId: change.doc.data()
                    .educationInstitutionId as string,
                  educationInstitutionClassrooms: change.doc.data()
                    .educationInstitutionClassrooms
                    ? (change.doc.data().educationInstitutionClassrooms as {
                        id: string;
                        name: string;
                      }[])
                    : [],
                  createdAt: (
                    change.doc.data().createdAt as Timestamp
                  ).toDate(),
                },
                changeType: change.type === 'added' ? 'added' : 'removed',
              });
            }
          });
        }
      );
    }
  }

  unsubscribeQuestionsForTeachers(): void {
    this.unsubscribeQuestionsForTeachersChanged &&
      this.unsubscribeQuestionsForTeachersChanged();
  }

  public async updateEducationInstitution(
    educationInstitution: IEducationInstitution
  ): Promise<FailableResult<null>> {
    const target = doc(
      this.db,
      'education_institutions',
      'v1',
      'profiles',
      educationInstitution.id
    );
    await updateDoc(target, {
      defaultProjectVisibility: educationInstitution.defaultProjectVisibility,
      gameTutorialVisible: educationInstitution.gameTutorialVisible,
      questionsFeatureAvailable: educationInstitution.questionsFeatureAvailable,
      supportedLocale: educationInstitution.supportedLocale,
    });
    return successResult();
  }
}
