import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import { SignIn } from '../dialogs/SignIn';
import { AccountInfo } from '../dialogs/AccountInfo';
import { useFirebaseAuth, SignInState } from '../services/FirebaseAuthContext';
import { EducationInstitutionSettings } from './contents/EducationInstitutionSettings';
import { IEducationInstitutionUser } from '../services/firebase/Firebase';
import { Loader } from './contents/Loader';

const drawerWidth = 360;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth.toString()}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth.toString()}px)`,
    marginLeft: `${drawerWidth.toString()}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type Dialog = 'signIn' | 'accountInformation';

interface pageData {
  path: string;
  element: () => JSX.Element | undefined;
  text: string;
}

/**
 * Determine if we should provide Education Institution chooser.
 *
 * We should provide the chooser when there are any other options available.
 *
 */
function shouldProvideChooser(
  educationInstitutionUser: IEducationInstitutionUser
): boolean {
  const possibleOptions = educationInstitutionUser.educationInstitutions.filter(
    (v) => {
      return (
        v.userType === 'teacher' &&
        v.id !== educationInstitutionUser.educationInstitution.id
      );
    }
  );
  return possibleOptions.length > 0;
}

export function Skeleton() {
  const theme = useTheme();

  const { t } = useTranslation();

  const location = useLocation();

  const navigate = useNavigate();
  console.log(`location: ${JSON.stringify(location)}`);

  const pages: pageData[] = [
    {
      path: '/',
      element: EducationInstitutionSettings,
      text: t('Education Institution Settings'),
    },
  ];

  const currentPage = pages.find((page) => {
    return page.path === location.pathname;
  });

  if (!currentPage) {
    throw new Error('404');
  }

  const [drawerState, setDrawerState] = React.useState(false);

  const toggleDrawer =
    (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerState(state);
    };

  const [dialogStates, setDialogStates] = React.useState({
    signIn: false,
    accountInformation: false,
  });

  const [anchorEls, setAnchorEls] = React.useState<
    Record<string, HTMLButtonElement | null>
  >({
    educaitonInstitutionSelector: null,
    accountMenu: null,
  });

  const [snackBarState, setSnackBarState] = React.useState(false);

  const openSnackBar = () => {
    setSnackBarState(true);
  };

  const closeSnackBar = (
    _event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState(false);
  };

  const openAccountMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEls({
      ...anchorEls,
      accountMenu: event.currentTarget,
    });
  };

  const closeAccountMenu = () => {
    setAnchorEls({
      ...anchorEls,
      accountMenu: null,
    });
  };

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEls({
      ...anchorEls,
      educationInstitutionSelector: event.currentTarget,
    });
  };

  const closePopover = () => {
    setAnchorEls({
      ...anchorEls,
      educationInstitutionSelector: null,
    });
  };

  const popoverState = Boolean(anchorEls.educationInstitutionSelector);

  const {
    user,
    userData,
    educationInstitutionUser,
    firebaseInstance,
    refreshEducationInstitutionUser,
    signInState,
  } = useFirebaseAuth();

  // Show loader when fetching data.
  const MainComponent =
    signInState === SignInState.FetchingData ? Loader : currentPage.element;

  // Show/hide the sign in dialog depending on the user's sign in state.
  useEffect(() => {
    // Show/hide the sign in dialog and account information dialog.
    setDialogStates({
      signIn: signInState === SignInState.SignedOut,
      accountInformation: false,
    });
  }, [signInState]);

  const updateEducationInstitution = async (educationInstitutionId: string) => {
    closePopover();
    openSnackBar();
    await firebaseInstance.updateUserSelectedEducationInstitution(
      educationInstitutionId
    );
    await refreshEducationInstitutionUser();
  };
  console.log(`user: ${JSON.stringify(user)}`);
  console.log(`userData: ${JSON.stringify(userData)}`);
  console.log(
    `educationInstitutionUser: ${JSON.stringify(educationInstitutionUser)}`
  );

  const toggleDialog = (dialog: Dialog, state: boolean) => {
    return () => {
      setDialogStates({ ...dialogStates, [dialog]: state });
    };
  };

  const onSignOut = function (): void {
    getAuth()
      .signOut()
      .then(() => {
        console.log(`user signed out`);
        setAnchorEls({
          ...anchorEls,
          accountMenu: null,
        });
        navigate('/');
      })
      .catch((error: unknown) => {
        const errorMessage =
          error instanceof Error ? error.message : 'unknown reason';
        console.log(`sign out failed: ${errorMessage}`);
        setAnchorEls({
          ...anchorEls,
          accountMenu: null,
        });
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: 64,
        width: '100%',
      }}
    >
      <CssBaseline />
      <AppBar position="fixed" open={drawerState}>
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              alignItems: 'center',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {user ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                edge="start"
                sx={{ mr: 2, ...(drawerState && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <></>
            )}
            <Typography variant="h6" noWrap component="div">
              Jasmine Teachers
            </Typography>
          </Box>
          {user ? (
            <Box sx={{ flexGrow: 0 }}>
              <ClickAwayListener onClickAway={closeAccountMenu}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={openAccountMenu}
                  color="inherit"
                >
                  {user.photoURL ? (
                    <Avatar alt={user.displayName ?? ''} src={user.photoURL} />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
              </ClickAwayListener>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEls.accountMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEls.accountMenu)}
                onClose={closeAccountMenu}
              >
                <MenuItem
                  onClick={() => {
                    toggleDialog('accountInformation', true)();
                  }}
                >
                  {t('Account Information')}
                </MenuItem>
                <MenuItem onClick={onSignOut}>{t('Sign out')}</MenuItem>
              </Menu>
            </Box>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerState}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer(false)}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map((pageData, _index) => (
            <ListItem key={pageData.path} disablePadding>
              <ListItemButton
                onClick={() => {
                  setDrawerState(false);
                  navigate(pageData.path);
                }}
                selected={pageData.path === location.pathname}
              >
                <ListItemText primary={pageData.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={drawerState}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
          }}
        >
          <MainComponent />
        </Box>
        <Paper sx={{ position: 'fixed', bottom: 0 }}>
          {educationInstitutionUser ? (
            <Button
              variant="text"
              onClick={openPopover}
              sx={{ textTransform: 'none' }}
              disabled={!shouldProvideChooser(educationInstitutionUser)}
            >
              {educationInstitutionUser.educationInstitution.name}
            </Button>
          ) : null}
        </Paper>
      </Main>
      <SignIn
        handleClose={toggleDialog('signIn', false)}
        open={dialogStates.signIn}
      />
      <AccountInfo
        open={dialogStates.accountInformation}
        handleClose={toggleDialog('accountInformation', false)}
      />
      <Popover
        id="educationInstitutionSelector"
        open={popoverState}
        anchorEl={anchorEls.educationInstitutionSelector}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {educationInstitutionUser?.educationInstitutions
            .filter((v) => v.userType === 'teacher')
            .map((institution, _index) => (
              <ListItem key={institution.name}>
                <ListItemButton
                  selected={
                    educationInstitutionUser.educationInstitution.id ===
                    institution.id
                  }
                  onClick={() => {
                    if (
                      educationInstitutionUser.educationInstitution.id !==
                      institution.id
                    ) {
                      void updateEducationInstitution(institution.id);
                    }
                  }}
                >
                  <ListItemText primary={institution.name} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Popover>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackBarState}
        autoHideDuration={4000}
        onClose={closeSnackBar}
        message={t('Changing Education Institution')}
      />
    </Box>
  );
}
