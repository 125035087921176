import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

export function PermissionWarning() {
  const { t } = useTranslation();
  return (
    <Alert severity="error">
      {t('The user is not registered as a teacher.')}
    </Alert>
  );
}
