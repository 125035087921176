// From src/utils/Types.ts
export interface IResult {
  success: boolean;
  error?: string;
  cause?: unknown;
}

export interface IError {
  message: string;
  cause?: unknown;
}

export type IOperationType =
  | 'account_registration_email_verified'
  | 'account_registration_with_apple'
  | 'account_registration_with_email_and_password'
  | 'account_registration_with_google'
  | 'account_registration_with_microsoft'
  | 'add_animation'
  | 'assign_animation'
  | 'boot'
  | 'cancel_animation_dialog'
  | 'cancel_background_dialog'
  | 'cancel_network_dialog'
  | 'cancel_sprite_dialog'
  | 'change_editor_color_theme'
  | 'change_auto_complete_mode'
  | 'change_editor_size'
  | 'change_screen_size'
  | 'change_test_feature'
  | 'change_user_password'
  | 'characters_measure'
  | 'close_notification_message'
  | 'copy_port_id'
  | 'copy_project'
  | 'copy_project_url'
  | 'create_project'
  | 'delete_animation'
  | 'delete_project'
  | 'delete_user_account'
  | 'done'
  | 'edit_program'
  | 'edit_project'
  | 'edit_test_program'
  | 'editor_copy'
  | 'editor_cut'
  | 'editor_delete'
  | 'editor_find'
  | 'editor_format'
  | 'editor_paste'
  | 'editor_redo'
  | 'editor_replace'
  | 'editor_select_all'
  | 'editor_undo'
  | 'erase_characters'
  | 'erase_graphics'
  | 'graphics_measure'
  | 'language'
  | 'link_with_apple'
  | 'link_with_google'
  | 'link_with_microsoft'
  | 'logout'
  | 'modify_animation'
  | 'no_measure'
  | 'open_account_registration'
  | 'open_account_setting_dialog'
  | 'open_animation_dialog'
  | 'open_background_dialog'
  | 'open_document_in_new_tab'
  | 'open_document_in_studio'
  | 'open_network_dialog'
  | 'open_project'
  | 'open_project_copy_dialog'
  | 'open_project_list_dialog'
  | 'open_sprite_dialog'
  | 'read_notification_message'
  | 'receive_notification_message'
  | 'register_account'
  | 'request_sign_in'
  | 'request_trial'
  | 'reset_password'
  | 'reset_password_email_sent'
  | 'reset_password_email_verified'
  | 'resume'
  | 'run'
  | 'run_test'
  | 'sign_in'
  | 'request_sign_in_with_apple'
  | 'request_sign_in_with_email_and_password'
  | 'request_sign_in_with_google'
  | 'request_sign_in_with_microsoft'
  | 'step_in'
  | 'stop'
  | 'toggle_breakpoint'
  | 'unassign_animation'
  | 'unlink_from_apple'
  | 'unlink_from_google'
  | 'unlink_from_microsoft'
  | 'update_background';

// From src/services/graphics/Sprite.ts
export const ALL_SPRITE_CHARACTER_SIZE = ['16', '32'] as const;
type spriteCharacterSizeTuple = typeof ALL_SPRITE_CHARACTER_SIZE;
export type ISpriteCharacterSize = spriteCharacterSizeTuple[number];

export const ALL_SPRITE_CHARACTER_DIRECTION = ['normal', 'reverse'] as const;
type spriteCharacterDirectionTuple = typeof ALL_SPRITE_CHARACTER_DIRECTION;
export type ISpriteCharacterDirection = spriteCharacterDirectionTuple[number];

export interface ISpriteCharacter {
  size: ISpriteCharacterSize;
  picIds: number[];
  direction: {
    x: ISpriteCharacterDirection;
    y: ISpriteCharacterDirection;
  };
}

export interface ISpriteAnimation {
  animationId: number;
  characters: ISpriteCharacter[];
}

// From src/services/graphics/Background.ts
export interface IBackground {
  backgroundId: number;
  data: number[];
  backgroundColor?: number;
}

// From src/ui/editor/types.ts
export enum AutoCompleteMode {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

// From src/services/resources/ResourceBundle
export type MessageResourceLanguage = 'ja_kanji' | 'en' | 'ja_kanji_elementary';

// From src/model/Models.ts

export type IQuestionType = 'question' | 'answer';

export interface IQuestion {
  id?: string;
  projectId: string;
  projectName: string;
  uid: string;
  displayName: string;
  photoUrl: string;
  questionType: IQuestionType;
  answered: boolean;
  content: string;
  educationInstitutionId: string;
  educationInstitutionClassrooms: { id: string; name: string }[];
  createdAt: Date;
}
