/**
 * A function call result which may have an error.
 *
 * Semantically, this class represents an optional error, and implemented as
 * a specialized type as `FailableResultWithValue<undefined, E>`
 * and error type, respectively.
 */
export type FailableResult<E> = FailableResultWithValue<undefined, E>;

/**
 * A result value which may have an error (a.k.a. "Either").
 * Type parameter {@code V} and {@code E} represents value type for success case
 * and error type, respectively.
 */
export type FailableResultWithValue<V, E> = SuccessResult<V> | ErrorResult<E>;

export interface SuccessResult<S> {
  readonly type: 'success';
  readonly value: S;
}

export interface ErrorResult<E> {
  readonly type: 'error';
  readonly error: E;
}

/**
 * Returns {@link SuccessResult} without any result value.
 * The base {@link FailableResultWithValue} must have `undefined` as the value
 * type.
 */
export const successResult = (): FailableResultWithValue<undefined, never> => {
  return { type: 'success', value: undefined };
};

/**
 * Creates a new {@link SuccessResult} instance as a
 * {@link FailableResultWithValue}.
 */
export const successResultOf = <S>(
  value: S
): FailableResultWithValue<S, never> => {
  return { type: 'success', value: value };
};

/**
 * Creates a new {@link ErrorResult} instance as a {@link FailableResultWithValue}.
 */
export const errorResultOf = <E>(
  error: E
): FailableResultWithValue<never, E> => {
  return { type: 'error', error: error };
};

/**
 * Returns true if the given {@link FailableResultWithValue} is {@link SuccessResult}.
 *
 * With guarding the return value by `true`, the argument is treated as a
 * `SuccessResult`.
 */
export const isSuccess = <S>(
  result: FailableResultWithValue<S, unknown>
): result is SuccessResult<S> => result.type === 'success';

/**
 * Returns true if the given {@link FailableResultWithValue} is {@link ErrorResult}.
 *
 * With guarding the return value by `false`, the argument is treated as a
 * `ErrorResult`.
 */
export const isError = <E>(
  result: FailableResultWithValue<unknown, E>
): result is ErrorResult<E> => result.type === 'error';
