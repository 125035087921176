import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useFirebaseAuth } from '../../services/FirebaseAuthContext';
import { isSuccess } from '../../jasmine-common-lib/utils/FailableResult';
import {
  IEducationInstitution,
  IProjectVisibility,
} from '../../services/firebase/Firebase';
import { MessageResourceLanguage } from '../../jasmine-common-lib/Types';
import { PermissionWarning } from './PermissionWarning';

interface SnackbarMessage {
  message: string;
  key: number;
}

const DEFAULT_EDUCATION_INSTITUTION: IEducationInstitution = {
  id: '',
  name: '',
  defaultProjectVisibility: 'hidden',
  gameTutorialVisible: false,
  questionsFeatureAvailable: false,
  supportedLocale: 'ja_kanji',
  docsMenu: '',
  classrooms: [],
  createdAt: new Date(),
  updatedAt: new Date(),
};

export function EducationInstitutionSettings() {
  const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>(
    []
  );
  const [snackBarState, setSnackBarState] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<
    SnackbarMessage | undefined
  >(undefined);
  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setSnackBarState(true);
    } else if (snackPack.length && messageInfo && snackBarState) {
      // Close an active snack when a new one is added
      setSnackBarState(false);
    }
  }, [snackPack, messageInfo, snackBarState]);
  const addSnackBar = (message: string) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };
  const closeSnackBar = (
    _event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };
  const { t } = useTranslation();
  const validationSchema = yup.object({
    defaultProjectVisibility: yup
      .mixed<IProjectVisibility>()
      .oneOf(Object.values(['public', 'hidden', 'education_institution']))
      .required(t('Required')),
    supportedLocale: yup
      .mixed<MessageResourceLanguage>()
      .oneOf(Object.values(['ja_kanji', 'ja_kanji_elementary', 'en']))
      .required(t('Required')),
    gameTutorialVisible: yup.boolean(),
    questionsFeatureAvailable: yup.boolean(),
  });

  const {
    educationInstitutionUser,
    firebaseInstance,
    refreshEducationInstitutionUser,
  } = useFirebaseAuth();

  const formik = useFormik({
    initialValues:
      educationInstitutionUser?.educationInstitution ??
      DEFAULT_EDUCATION_INSTITUTION,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      addSnackBar(t('Updating Education Institution'));
      try {
        const result =
          await firebaseInstance.updateEducationInstitution(values);
        if (isSuccess(result)) {
          addSnackBar(t('Education Institution has been successfully updated'));
        } else {
          addSnackBar(t('Failed to update Education Institution'));
        }
      } catch (e) {
        console.error(e);
        addSnackBar(t('Failed to update Education Institution'));
      } finally {
        setSubmitting(false);
      }
      setSubmitting(false);
      await refreshEducationInstitutionUser();
    },
  });

  if (educationInstitutionUser) {
    if (educationInstitutionUser.userType !== 'teacher') {
      return <PermissionWarning />;
    }
    return (
      <>
        <Typography variant="h5">
          {t('Education Institution Settings')}
        </Typography>
        <Divider />
        <Box component="section" sx={{ display: 'block', margin: '16px 0px' }}>
          {t('Education Institution Name')}:{' '}
          {educationInstitutionUser.educationInstitution.name}
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ display: 'block', margin: '16px 0px' }}>
            <Box component="section" sx={{ display: 'flex' }}>
              <InputLabel shrink id="defaultProjectVisibility">
                {t('Default Project Visibility')}
              </InputLabel>
              <Select
                name="defaultProjectVisibility"
                labelId="defaultProjectVisibility"
                id="defaultProjectVisibilityInput"
                value={formik.values.defaultProjectVisibility}
                label={t('Default Project Visibility')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.defaultProjectVisibility &&
                  Boolean(formik.errors.defaultProjectVisibility)
                }
                displayEmpty
              >
                <MenuItem value="" disabled>
                  {t('Please Select')}
                </MenuItem>
                <MenuItem value="public">
                  {t('project_visibility_public')}
                </MenuItem>
                <MenuItem value="hidden">
                  {t('project_visibility_hidden')}
                </MenuItem>
                <MenuItem value="education_institution">
                  {t('project_visibility_education_institution')}
                </MenuItem>
              </Select>
            </Box>
          </FormControl>
          <FormControl sx={{ display: 'block', margin: '16px 0px' }}>
            <Box component="section" sx={{ display: 'flex' }}>
              <InputLabel shrink id="supportedLocale">
                {t('Supported Locale')}
              </InputLabel>
              <Select
                name="supportedLocale"
                labelId="supportedLocale"
                id="supportedLocaleInput"
                value={formik.values.supportedLocale}
                label={t('Supported Locale')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.supportedLocale &&
                  Boolean(formik.errors.supportedLocale)
                }
                displayEmpty
              >
                <MenuItem value="" disabled>
                  {t('Please Select')}
                </MenuItem>
                <MenuItem value="ja_kanji">
                  {t('supported_locale_ja_kanji')}
                </MenuItem>
                <MenuItem value="ja_kanji_elementary">
                  {t('supported_locale_ja_kanji_elementary')}
                </MenuItem>
                <MenuItem value="en">{t('supported_locale_en')}</MenuItem>
              </Select>
            </Box>
          </FormControl>
          <Box component="section" sx={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.gameTutorialVisible}
                  onChange={() => {
                    void formik.setFieldValue(
                      'gameTutorialVisible',
                      !formik.values.gameTutorialVisible
                    );
                  }}
                />
              }
              label={t('Show game tutorial')}
            />
          </Box>
          <Box component="section" sx={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.questionsFeatureAvailable}
                  onChange={() => {
                    void formik.setFieldValue(
                      'questionsFeatureAvailable',
                      !formik.values.questionsFeatureAvailable
                    );
                  }}
                />
              }
              label={t('Make Question feature available')}
            />
          </Box>
          <Divider />
          <Button
            variant="contained"
            type="submit"
            sx={{ margin: '16px 0px', textTransform: 'none' }}
            disabled={!formik.dirty}
          >
            {t('Update Education Institution Settings')}
          </Button>
        </form>
        <Snackbar
          key={messageInfo?.key}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackBarState}
          autoHideDuration={6000}
          onClose={closeSnackBar}
          TransitionProps={{ onExited: handleExited }}
          message={messageInfo?.message}
        />
      </>
    );
  }
}
