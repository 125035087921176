export const DEFAULT_SYNTAX_HIGHLIGHT_THEME = 'light_g';

export const OLD_ALL_JASMINE_COLOR_THEME_NAMES = [
  '淡い配色',
  'ライトG',
] as const;
type oldJasmineColorThemeTuple = typeof OLD_ALL_JASMINE_COLOR_THEME_NAMES;
export type IOldJasmineColorThemeNames = oldJasmineColorThemeTuple[number];

export const ALL_JASMINE_COLOR_THEME_NAMES = ['pale', 'light_g'] as const;
type jasmineColorThemeTuple = typeof ALL_JASMINE_COLOR_THEME_NAMES;
export type IJasmineColorThemeNames = jasmineColorThemeTuple[number];

export const OLD_NEW_JASMINE_COLOR_THEME_NAME_MAP: Record<
  IOldJasmineColorThemeNames,
  IJasmineColorThemeNames
> = {
  淡い配色: 'pale',
  ライトG: 'light_g',
};
