import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslation from './en/translation.json';
import japaneseTranslation from './ja/translation.json';

export const defaultNS = 'translation';

void i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'ja', // if you're using a language detector, do not define the lng option
  debug: true,
  defaultNS: defaultNS,
  resources: {
    en: {
      translation: englishTranslation,
    },
    ja: {
      translation: japaneseTranslation,
    },
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  returnNull: false,
});

export default i18next;
