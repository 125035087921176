import { FirebaseConfiguration } from './Firebase';

export const firebaseConfigurationByImportMetaEnv: FirebaseConfiguration = {
  apiKey: import.meta.env.JASMINE_FIREBASE_API_KEY as string | undefined,
  authDomain: import.meta.env.JASMINE_FIREBASE_AUTH_DOMAIN as
    | string
    | undefined,
  projectId: import.meta.env.JASMINE_FIREBASE_PROJECT_ID as string | undefined,
  storageBucket: import.meta.env.JASMINE_FIREBASE_STORAGE_BUCKET as
    | string
    | undefined,
  messagingSenderId: import.meta.env.JASMINE_FIREBASE_MESSAGING_SENDER_ID as
    | string
    | undefined,
  appId: import.meta.env.JASMINE_FIREBASE_APP_ID as string | undefined,
  measurementId: import.meta.env.JASMINE_FIREBASE_MEASUREMENT_ID as
    | string
    | undefined,
};
