import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export function Loader() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 150px)',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
